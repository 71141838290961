/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Container, HeaderSVG, MaxWidthContainer, SVGShapeContainer } from '../pages/projects';
import { SVGShapeHeader } from '../components/svgs';
import { Title } from '../components/styles/Title';
import { Spacer } from '../components/styles/Spacer';
import Box from '../components/Box';
// import ClockIcon from 'react-icons/lib/fa/clock-o';

const StyledPost = styled.div`
  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: var(--dark-blue);
    margin-top: 0;
  }

  .language-text {
    padding: 2px 6px;
    background: var(--dark-blue);
  }

  .gatsby-highlight {
    margin: 20px 0;
    position: relative;
    padding-top: 30px;

    &:after {
      padding: 8px 16px;
      background: var(--dark-blue);
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 4px;
    }

    &[data-language='js']:after {
      content: 'JavaScript';
    }

    &[data-language='html']:after {
      content: 'HTML';
    }

    &[data-language='css']:after {
      content: 'CSS';
    }

    &[data-language='java']:after {
      content: 'Java';
    }

    &[data-language='shell']:after {
      content: 'Shell';
    }
  }

  figure {
    margin: 2rem 0;

    figcaption {
      text-align: center;
      padding: 8px 0;
      color: var(--gray-4);
    }
  }

  p,
  ul {
    line-height: 30px;
  }

  ul {
    list-style: disc;
    margin-left: 16px;
  }

  @media (${props => props.theme.bp.mobileL}) {
    font-size: 87.5%;
  }
`;

const StyledDate = styled.div`
  font-size: 18px;
`;

const PostTemplate = ({ data }) => {
  const { markdownRemark: post } = data;
  const { title, tags, date } = post.frontmatter;

  return (
    <Layout>
      <SEO title={title} />
      <Container>
        <HeaderSVG>
          <SVGShapeContainer>
            <SVGShapeHeader />
          </SVGShapeContainer>
        </HeaderSVG>
        <MaxWidthContainer>
          <Title>{title}</Title>
          <StyledDate>{format(new Date(date), 'MMM dd, yyyy')}</StyledDate>
          <Spacer h='24px' />
          <Box
            display='flex'
            justifyContent='space-between'
            mb='24px'
            color='var(--gray-4)'
            opacity={0.5}
            fontSize='14px'
          >
            <Box>
              {tags.map((tag, i) => (
                <Box display='inline-block' mr='5px' key={tag}>
                  {tag}&nbsp;{i < tags.length - 1 ? '‧' : ''}
                </Box>
              ))}
            </Box>
            <Box display='flex' alignItems='center'>
              <Box mr='8px' style={{ transform: 'translateY(-1px)' }}>
                {/* prettier-ignore */}
                <svg width='14px' height='14px' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </Box>
              {post.timeToRead} Min
            </Box>
          </Box>
          <StyledPost dangerouslySetInnerHTML={{ __html: post.html }} />
        </MaxWidthContainer>
      </Container>
    </Layout>
  );
};
export default PostTemplate;

export const postQuery = graphql`
  query BlogPostByPath($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        date
        tags
      }
    }
  }
`;
